import { useEffect, useState } from "react";
import { Card } from "antd";

import { GET } from "../../../../providers/useAxiosQuery";
import leftArrow from "../../../../assets/img/left-arrow.png";

export default function FacultyMonitoringFacultyTopGraph(props) {
	const { Highcharts } = props;

	// const [filter, setFilter] = useState({
	// 	action: "department",
	// 	time: "",
	// 	day: "",
	// 	month: "",
	// 	department_id: "",
	// });

	// const { refetch: refetchSource } = GET(
	// 	`api/faculty_load_monitoring_faculty_top_graph?${new URLSearchParams(
	// 		filter
	// 	)}`,
	// 	"faculty_load_monitoring_faculty_top_graph",
	// 	(res) => {
	// 		console.log("faculty_load_monitoring_faculty_top_graph", res);

	// 		if (res.data) {
	// 			let data = res.data;
	// 			let action = res.data.action;

	// 			let title = "CLASS SCHEDULE TIME";
	// 			let subtitleText = "CLICK THE COLUMNS TO VIEW PER DAY";
	// 			let xAxisTitle = "TIME";

	// 			if (action === "timely") {
	// 				title = "CLASS SCHEDULE TIME";
	// 				subtitleText = "CLICK THE COLUMNS TO VIEW PER DAY";
	// 				xAxisTitle = "CLASS SCHEDULE TIME";
	// 			} else if (action === "week_days") {
	// 				title = "WEEK DAYS";
	// 				subtitleText = "CLICK THE COLUMNS TO VIEW PER MONTH";
	// 				xAxisTitle = "DAY";
	// 			} else if (action === "monthly") {
	// 				title = "MONTHLY";
	// 				subtitleText = "CLICK THE COLUMNS TO VIEW PER DAILY";
	// 				xAxisTitle = "MONTH";
	// 			} else if (action === "daily") {
	// 				title = "DAILY";
	// 				subtitleText = "CLICK THE COLUMNS TO GO BACK TO PER YEAR";
	// 				xAxisTitle = "DAY";
	// 			}

	// 			let data_series_value_filter = data.data_series_value.filter(
	// 				(f) => f.y !== 0
	// 			);

	// 			if (data_series_value_filter.length === 0) {
	// 				subtitleText = "NO DATA TO VIEW";
	// 			}

	// 			if (document.getElementById("divFacultyMonitoringGraph")) {
	// 				let chart = Highcharts.chart(
	// 					"divFacultyMonitoringGraph",
	// 					{
	// 						chart: {
	// 							// zoomType: "x",
	// 							type: action === "timely" ? "bar" : "column",
	// 							events: {
	// 								// load: function (event) {
	// 								// 	event.target.reflow();
	// 								// },
	// 								click: function (e) {
	// 									let action = data.action;
	// 									let downTo = data.downTo;
	// 									let series_name =
	// 										data.data_series_name[
	// 											Math.abs(Math.round(e.xAxis[0].value))
	// 										];

	// 									if (action === "timely") {
	// 										setFilter((prevState) => ({
	// 											...prevState,
	// 											action: downTo,
	// 											time: series_name,
	// 										}));
	// 									}

	// 									if (action === "week_days") {
	// 										setFilter((prevState) => ({
	// 											...prevState,
	// 											action: downTo,
	// 											day: series_name,
	// 										}));
	// 									}
	// 									if (action === "monthly") {
	// 										setFilter((prevState) => ({
	// 											...prevState,
	// 											action: downTo,
	// 											month: series_name,
	// 										}));
	// 									}
	// 									if (action === "daily") {
	// 										setFilter((prevState) => ({
	// 											...prevState,
	// 											action: downTo,
	// 										}));
	// 									}

	// 									let div_revenue_graph_wrapper = document.querySelector(
	// 										"#divFacultyMonitoringGraph"
	// 									);
	// 									if (div_revenue_graph_wrapper) {
	// 										let highchartsDataTable =
	// 											div_revenue_graph_wrapper.querySelector(
	// 												".highcharts-data-table"
	// 											);
	// 										if (highchartsDataTable) {
	// 											highchartsDataTable.remove();
	// 										}
	// 									}
	// 								},
	// 							},
	// 						},
	// 						title: {
	// 							text: title,
	// 						},
	// 						subtitle: {
	// 							text: subtitleText,
	// 						},
	// 						xAxis: {
	// 							title: {
	// 								text: xAxisTitle,
	// 								enabled: false,
	// 							},
	// 							categories: data.data_series_name,
	// 							crosshair: true,
	// 							type: "category",
	// 						},
	// 						yAxis: {
	// 							title: {
	// 								text: null,
	// 							},
	// 							// labels: {
	// 							// 	formatter: function () {
	// 							// 		return `${Highcharts.numberFormat(this.value, 0, "", ",")}`;
	// 							// 	},
	// 							// },
	// 						},
	// 						tooltip: {
	// 							headerFormat:
	// 								'<span style="font-size:10px">{point.key}</span><table>',
	// 							pointFormat:
	// 								'<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
	// 								'<td style="padding:0"><b>{point.y}</b></td></tr>',
	// 							footerFormat: "</table>",
	// 							shared: true,
	// 							useHTML: true,
	// 						},
	// 						legend: {
	// 							layout: "vertical",
	// 							align: "right",
	// 							verticalAlign: "middle",
	// 						},
	// 						plotOptions: {
	// 							series: {
	// 								borderWidth: 0,
	// 								dataLabels: {
	// 									enabled: false,
	// 									// format: "{point.y:.2f}",
	// 								},
	// 								cursor: "pointer",
	// 							},
	// 							bar: {
	// 								borderWidth: 10,
	// 								events: {
	// 									click: function (e) {
	// 										let action = data.action;
	// 										let downTo = data.downTo;

	// 										if (action === "timely") {
	// 											setFilter((prevState) => ({
	// 												...prevState,
	// 												action: downTo,
	// 												time: e.point.category,
	// 											}));
	// 										}

	// 										let div_revenue_graph_wrapper = document.querySelector(
	// 											"#divFacultyMonitoringGraph"
	// 										);

	// 										if (div_revenue_graph_wrapper) {
	// 											let highchartsDataTable =
	// 												div_revenue_graph_wrapper.querySelector(
	// 													".highcharts-data-table"
	// 												);
	// 											if (highchartsDataTable) {
	// 												highchartsDataTable.remove();
	// 											}
	// 										}
	// 									},
	// 								},
	// 							},
	// 							column: {
	// 								pointPadding: 0.2,
	// 								borderWidth: 10,
	// 								dataLabels: {
	// 									enabled: false,
	// 									// format: "{point.y:.0f}",
	// 									formatter: function () {
	// 										if (this.y === 0) {
	// 											return null;
	// 										}
	// 										return this.y.toFixed(2);
	// 									},
	// 								},
	// 								events: {
	// 									click: function (e) {
	// 										let action = data.action;
	// 										let downTo = data.downTo;

	// 										if (action === "week_days") {
	// 											setFilter((prevState) => ({
	// 												...prevState,
	// 												action: downTo,
	// 												day: e.point.category,
	// 											}));
	// 										}

	// 										if (action === "monthly") {
	// 											setFilter((prevState) => ({
	// 												...prevState,
	// 												action: downTo,
	// 												month: e.point.category,
	// 											}));
	// 										}

	// 										if (action === "daily") {
	// 											setFilter((prevState) => ({
	// 												...prevState,
	// 												action: downTo,
	// 											}));
	// 										}

	// 										let div_revenue_graph_wrapper = document.querySelector(
	// 											"#divFacultyMonitoringGraph"
	// 										);

	// 										if (div_revenue_graph_wrapper) {
	// 											let highchartsDataTable =
	// 												div_revenue_graph_wrapper.querySelector(
	// 													".highcharts-data-table"
	// 												);
	// 											if (highchartsDataTable) {
	// 												highchartsDataTable.remove();
	// 											}
	// 										}
	// 									},
	// 								},
	// 							},
	// 						},
	// 						series: data.data_series_value,
	// 						exporting: {
	// 							filename: `report-revenue-${title.toLowerCase()}`,
	// 							buttons: {
	// 								contextButton: {
	// 									symbolStroke: "#f58d13",
	// 									symbolX: 14,
	// 									menuItems: [
	// 										"printChart",
	// 										"separator",
	// 										"downloadPNG",
	// 										"downloadJPEG",
	// 										"downloadPDF",
	// 										"downloadSVG",
	// 										"separator",
	// 										// "downloadCSV",
	// 										"toggleTable",
	// 									],
	// 								},
	// 							},
	// 							menuItemDefinitions: {
	// 								// Custom definition
	// 								toggleTable: {
	// 									onclick: function () {
	// 										if (
	// 											this.dataTableDiv &&
	// 											this.dataTableDiv.style.display !== "none"
	// 										) {
	// 											this.dataTableDiv.style.display = "none";
	// 										} else {
	// 											this.viewData();
	// 											this.dataTableDiv.style.display = "";
	// 										}
	// 									},
	// 									text: "View Data Table",
	// 								},
	// 							},
	// 						},
	// 					},
	// 					function (chart) {
	// 						// on complete

	// 						if (data.action !== "timely") {
	// 							let y = 65;
	// 							let x = 15;

	// 							// console.log("x: ", x, " y: ", y);
	// 							chart.renderer
	// 								.image(leftArrow, chart.chartWidth - y, x, 18, 17)
	// 								.add()
	// 								.addClass("highcharts-button-arrow-left")
	// 								.css({ cursor: "pointer" })
	// 								.attr({ title: "Back" })
	// 								.on("click", function () {
	// 									// prcessing after image is clicked
	// 									let action = res.data.action;

	// 									if (action === "week_days") {
	// 										setFilter((prevState) => ({
	// 											...prevState,
	// 											action: "timely",
	// 										}));
	// 									}
	// 									if (action === "monthly") {
	// 										setFilter((prevState) => ({
	// 											...prevState,
	// 											action: "week_days",
	// 										}));
	// 									}

	// 									if (action === "daily") {
	// 										setFilter((prevState) => ({
	// 											...prevState,
	// 											action: "monthly",
	// 										}));
	// 									}
	// 								});
	// 						}
	// 					}
	// 				);

	// 				Highcharts.addEvent(chart, "aftergetTableAST", function (e) {
	// 					e.tree.children[2].children.forEach(function (row) {
	// 						row.children.forEach(function (cell, i) {
	// 							if (i !== 0) {
	// 								row.children[i].textContent = cell.textContent;
	// 							}
	// 						});
	// 					});
	// 				});
	// 			}
	// 		}
	// 	},
	// 	false
	// );

	// useEffect(() => {
	// 	refetchSource();

	// 	return () => {};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [filter]);

	return null;
	// return <Card>FACULTYMONITORINGFACULTYTOPGRAPH IS UNDER CONSTRUCTION</Card>;
}
