import { Calendar, Card } from "antd";

export default function FacultyMonitoringCalendar() {
	const onPanelChange = (value, mode) => {};

	return (
		<Card>
			<Calendar fullscreen={false} onPanelChange={onPanelChange} />
		</Card>
	);
}
