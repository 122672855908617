import { Card, Col, Row, Statistic } from "antd";
import CountUp from "react-countup";

const formatter = (value) => <CountUp end={value} separator="," />;

export default function FacultyMonitoringListGraph(props) {
	const { dataSource } = props;

	const RenderGraph = () => {
		let present = 0;
		let absentToday = 0;
		let absentMonth = 0;
		let absentYearly = 0;

		if (dataSource && dataSource.data) {
			dataSource.data.forEach((item) => {
				if (item.label === "Present") {
					present = item.data;
				} else {
					if (item.by === "today") {
						absentToday = item.data;
					}
				}
			});
		}

		let departmentList = [];

		if (dataSource && dataSource.departmentList) {
			dataSource.departmentList.forEach((item) => {
				// console.log("item: ", item);

				departmentList.push(
					<Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
						<Card title={item.department_name}>
							<Statistic
								// title="Active Users"
								value={item.count}
								formatter={formatter}
							/>
						</Card>
					</Col>
				);
			});
		}

		return (
			<Row gutter={[20, 20]}>
				<Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
					<Card title="Present" extra={<>TODAY</>}>
						<Statistic
							// title="Active Users"
							value={present}
							formatter={formatter}
						/>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
					<Card title="Absent" extra={<>TODAY</>}>
						<Statistic
							// title="Active Users"
							value={absentToday}
							formatter={formatter}
						/>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
					<Card title="Absent" extra={<>MONTHLY</>}>
						<Statistic
							// title="Active Users"
							value={absentMonth}
							formatter={formatter}
						/>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
					<Card title="Absent" extra={<>YEARLY</>}>
						<Statistic
							// title="Active Users"
							value={absentYearly}
							formatter={formatter}
						/>
					</Card>
				</Col>
			</Row>
		);
	};

	return <RenderGraph />;
}
