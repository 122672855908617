import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Form, Select, DatePicker, Button, Flex, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons";

import { GET } from "../../../providers/useAxiosQuery";
import { apiUrl, role } from "../../../providers/companyInfo";
import {
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../providers/CustomTableFilter";

export default function PageFacultyLoadReport() {
	const location = useLocation();

	const [form] = Form.useForm();

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		status: "Active",
		from: "faculty_load_monitoring_report",
		building_id: [],
		floor_id: [],
		room_id: [],
		status_id: "",
		department_id: "",
		date_range: "",
	});

	const [dataRooms, setDataRooms] = useState([]);

	const { data: dataBuildings } = GET(
		"api/building_with_children",
		"building_select",
		(res) => {},
		false
	);

	const { data: dataFloors } = GET(
		`api/ref_floor`,
		"floor_select",
		(res) => {},
		false
	);

	const { data: dataStatus } = GET(
		`api/ref_status?status_category_id=1`,
		"status_selectss",
		(res) => {},
		false
	);

	const { data: dataDepartments } = GET(
		`api/ref_department`,
		"department_status",
		(res) => {},
		false
	);

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/faculty_load_monitoring?${new URLSearchParams(tableFilter)}`,
		"faculty_load_monitoring_list"
	);

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	useTableScrollOnTop("tbl1", location.pathname);

	return (
		<Row gutter={[15, 15]} justify="center" id="tbl_wrapper">
			<Col xs={24} sm={24} md={24} lg={24}>
				<Form form={form}>
					<Row gutter={[15, 15]} id="tbl_wrapper">
						<Col xs={24} sm={24} md={6} lg={6}>
							<Form.Item name="date_range" noStyle>
								<DatePicker.RangePicker
									placeholder={["From", "To"]}
									className="w-100 filter"
									size="large"
									showSearch
									allowClear
									options={
										dataBuildings
											? dataBuildings.data.map((item) => {
													return {
														label: item.building,
														value: item.id,
													};
											  })
											: []
									}
									onChange={(date, dateString) => {
										console.log("date", date);
										console.log("dateString", dateString);

										setTableFilter((prevState) => ({
											...prevState,
											date_range: dateString,
										}));
									}}
								/>
							</Form.Item>
						</Col>

						<Col xs={24} sm={24} md={6} lg={6}>
							<Form.Item name="building_id" noStyle>
								<Select
									label="Building"
									placeholder="Building"
									className="w-100 filter"
									size="large"
									showSearch
									allowClear
									options={
										dataBuildings
											? dataBuildings.data.map((item) => {
													return {
														label: item.building,
														value: item.id,
													};
											  })
											: []
									}
									onChange={(value) => {
										let building_id = value ? value : "";
										let floor_id = form.getFieldValue("floor_id");

										setTableFilter((prevState) => ({
											...prevState,
											building_id: value ? value : "",
										}));

										if (value) {
											let building = dataBuildings.data.find(
												(x) =>
													x.id === building_id &&
													x.rooms.filter((y) => y.floor_id === floor_id)
														.length > 0
											);
											console.log("building: ", building);

											setDataRooms(building ? building.rooms : []);
										} else {
											setDataRooms([]);
										}

										form.resetFields(["room_id"]);
									}}
								/>
							</Form.Item>
						</Col>

						<Col xs={24} sm={24} md={6} lg={6}>
							<Form.Item name="floor_id" noStyle>
								<Select
									label="Floor"
									placeholder="Floor"
									className="w-100 filter"
									size="large"
									allowClear
									options={
										dataFloors
											? dataFloors.data.map((item) => {
													return {
														label: item.floor,
														value: item.id,
													};
											  })
											: []
									}
									onChange={(value) => {
										let building_id = form.getFieldValue("building_id");
										let floor_id = value ? value : "";

										setTableFilter((prevState) => ({
											...prevState,
											floor_id: value ? value : "",
										}));

										if (value) {
											let building = dataBuildings.data.find(
												(x) =>
													x.id === building_id &&
													x.rooms.filter((y) => y.floor_id === floor_id)
														.length > 0
											);
											setDataRooms(building ? building.rooms : []);
										} else {
											setDataRooms([]);
										}

										form.resetFields(["room_id"]);
									}}
								/>
							</Form.Item>
						</Col>

						<Col xs={24} sm={24} md={6} lg={6}>
							<Form.Item name="room_id" noStyle>
								<Select
									label="Room"
									placeholder="Room"
									className="w-100 filter"
									size="large"
									allowClear
									options={dataRooms.map((item) => {
										return {
											label: item.room_code,
											value: item.id,
										};
									})}
									onChange={(value) => {
										setTableFilter((prevState) => ({
											...prevState,
											room_id: value ? value : "",
										}));
									}}
								/>
							</Form.Item>
						</Col>

						<Col xs={24} sm={24} md={6} lg={6}>
							<Form.Item name="status_id" noStyle>
								<Select
									label="Status"
									placeholder="Status"
									className="w-100 filter"
									size="large"
									allowClear
									options={
										dataStatus
											? dataStatus.data.map((item) => {
													return {
														label: item.status,
														value: item.id,
													};
											  })
											: []
									}
									onChange={(value) => {
										setTableFilter((prevState) => ({
											...prevState,
											status_id: value ? value : "",
										}));
									}}
								/>
							</Form.Item>
						</Col>

						{role() !== 3 ? (
							<Col xs={24} sm={24} md={6} lg={6}>
								<Form.Item name="department_id">
									<Select
										label="Department"
										placeholder="Department"
										className="w-100 filter"
										size="large"
										allowClear
										options={
											dataDepartments
												? dataDepartments.data.map((item) => {
														return {
															label: item.department_name,
															value: item.id,
														};
												  })
												: []
										}
										onChange={(value) => {
											setTableFilter((prevState) => ({
												...prevState,
												department_id: value ? value : "",
											}));
										}}
									/>
								</Form.Item>
							</Col>
						) : null}
					</Row>
				</Form>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<div className="tbl-top-filter">
					<Button
						icon={<FontAwesomeIcon icon={faFilePdf} />}
						className="btn-main-primary"
						onClick={() => {
							window.open(
								apiUrl(
									`api/faculty_load_monitoring_print?${new URLSearchParams(
										tableFilter
									)}`
								),
								"_blank"
							);
						}}
					>
						PDF Print
					</Button>

					<TablePageSize
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>
				</div>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<div className="tbl-top-filter">
					<div />

					<Flex gap={15}>
						<TableShowingEntriesV2 />

						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</div>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Table
					id="tbl1"
					className="ant-table-default ant-table-striped"
					dataSource={dataSource && dataSource.data.data}
					rowKey={(record) => record.id}
					pagination={false}
					bordered={false}
					onChange={onChangeTable}
					scroll={{ x: "max-content" }}
				>
					<Table.Column
						title="Date Created"
						key="created_at_format"
						dataIndex="created_at_format"
						defaultSortOrder={"descend"}
						width={150}
						sorter
					/>

					<Table.Column
						title="Status"
						key="status"
						dataIndex="status"
						width={230}
						sorter
					/>

					<Table.Column
						title="Justification Status"
						key="status_update"
						dataIndex="status_update"
						width={200}
						sorter
					/>

					<Table.Column
						title="Justification Remarks"
						key="update_remarks"
						dataIndex="update_remarks"
						width={200}
						sorter
					/>

					<Table.Column
						title="Faculty No."
						key="faculty_no"
						dataIndex="faculty_no"
						width={130}
						sorter
					/>
					<Table.Column
						title="Name"
						key="fullname"
						dataIndex="fullname"
						width={200}
						sorter
					/>
					<Table.Column
						title="Time"
						key="time"
						dataIndex="time"
						width={180}
						sorter
					/>
					<Table.Column
						title="Room"
						key="room_code"
						dataIndex="room_code"
						width={120}
						sorter
					/>
					<Table.Column
						title="Subject"
						key="code"
						dataIndex="code"
						width={120}
						sorter
					/>
					<Table.Column
						title="School Year"
						key="school_year"
						dataIndex="school_year"
						width={150}
						sorter
					/>
					<Table.Column
						title="Semester"
						key="semester"
						dataIndex="semester"
						width={180}
						sorter
					/>
				</Table>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<div className="tbl-bottom-filter">
					<TableShowingEntriesV2 />
					<TablePagination
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
						total={dataSource?.data.total}
						showLessItems={true}
						showSizeChanger={false}
						tblIdWrapper="tbl_wrapper"
					/>
				</div>
			</Col>
		</Row>
	);
}
