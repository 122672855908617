import { Card, Col, Row, Statistic } from "antd";
import CountUp from "react-countup";

const formatter = (value) => <CountUp end={value} separator="," />;

export default function FacultyMonitoringDepartmentListGraph(props) {
	const { dataSource } = props;

	const RenderGraph = () => {
		let departmentList = [];

		if (dataSource && dataSource.departmentList) {
			dataSource.departmentList.forEach((item, index) => {
				departmentList.push(
					<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} key={index}>
						<Card title={item.department_name} extra={<>ABSENT</>}>
							<Statistic
								// title="Active Users"
								value={item.count}
								formatter={formatter}
							/>
						</Card>
					</Col>
				);
			});
		}

		return <Row gutter={[20, 20]}>{departmentList}</Row>;
	};

	return <RenderGraph />;
}
