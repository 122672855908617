import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Dropdown, Image, Layout, Typography } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEdit, faPowerOff } from "@fortawesome/pro-regular-svg-icons";

import { GET, POST } from "../../providers/useAxiosQuery";
import {
	apiUrl,
	defaultProfile,
	userData,
	role,
	system_id,
} from "../../providers/companyInfo";
import notificationErrors from "../../providers/notificationErrors";

export default function Header(props) {
	const { width, sideMenuCollapse, setSideMenuCollapse } = props;

	const navigate = useNavigate();

	const [profileImage, setProfileImage] = useState(defaultProfile);

	useEffect(() => {
		if (userData() && userData().profile_picture) {
			let profileImage = userData().profile_picture.split("//");
			if (profileImage[0] === "http:" || profileImage[0] === "https:") {
				setProfileImage(userData().profile_picture);
			} else {
				setProfileImage(apiUrl(userData().profile_picture));
			}
		}

		return () => {};
	}, []);

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("userdata");
		window.location.reload();
	};

	const [notificationCount, setNotificationCount] = useState(0);

	const { data: dataNotifications } = GET(
		`api/user_notifications?user_id=${userData().id}&system_id=${system_id}`,
		"user_notifications",
		(res) => {
			if (res.data.length > 0) {
				let unread = res.data.filter((item) => item.read === 0);
				setNotificationCount(unread.length);
			}
		},
		false
	);

	const { mutate: mutateUpdateNotification } = POST(
		`api/update_notification`,
		"user_notifications",
		false
	);

	const handleUpdateNotification = (item) => {
		navigate(item.notification.link);
		mutateUpdateNotification(item, {
			onSuccess: (res) => {
				if (res.success) {
					setNotificationCount(notificationCount - 1);
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const RenderMenuNotification = () => {
		const items = dataNotifications
			? dataNotifications.data.filter((f) => f.read === 0)
			: [];

		return (
			<div className="notification-wrapper">
				{items.length > 0 ? (
					items.map((item, index) => {
						return (
							<div
								className="notification-item"
								key={index}
								onClick={() => handleUpdateNotification(item)}
							>
								<div className="notification-item-title">
									{item.notification.title}
								</div>
								<div className="notification-item-description">
									{item.notification.description}
								</div>
							</div>
						);
					})
				) : (
					<div className="no-notification">No notifications</div>
				)}
			</div>
		);
	};

	const menuProfile = () => {
		const items = [
			{
				key: "/account/details",
				className: "menu-item-profile-details",
				label: (
					<div className="menu-item-details-wrapper">
						<Image
							preview={false}
							src={profileImage}
							alt={userData().username}
						/>

						<div className="info-wrapper">
							<Typography.Text className="info-username">
								{userData().firstname} {userData().lastname}
							</Typography.Text>

							<br />
							<Typography.Text className="info-role">{role()}</Typography.Text>
						</div>
					</div>
				),
			}, // remember to pass the key prop
			{
				key: "/edit-profile",
				icon: <FontAwesomeIcon icon={faEdit} />,
				label: <Link to="/edit-profile">Edit Account Profile</Link>,
			}, // which is required
		];

		items.push({
			key: "/signout",
			className: "ant-menu-item-logout",
			icon: <FontAwesomeIcon icon={faPowerOff} />,
			label: <Typography.Link onClick={handleLogout}>Sign Out</Typography.Link>,
		});

		return { items };
	};

	return (
		<Layout.Header>
			<div className="header-left-menu">
				{width < 768 && (
					<div className="menu-left-icon menu-left-icon-menu-collapse-on-close">
						{sideMenuCollapse ? (
							<MenuUnfoldOutlined
								onClick={() => setSideMenuCollapse(false)}
								className="menuCollapseOnClose"
							/>
						) : (
							<MenuFoldOutlined
								onClick={() => setSideMenuCollapse(true)}
								className="menuCollapseOnClose"
							/>
						)}
					</div>
				)}
			</div>

			<div className="header-right-menu">
				<Dropdown
					menu={menuProfile()}
					placement="bottomRight"
					overlayClassName="menu-submenu-profile-popup"
					trigger={["click"]}
				>
					<Image
						preview={false}
						rootClassName="menu-submenu-profile"
						src={profileImage}
						alt={userData().username}
					/>
				</Dropdown>
				<Dropdown
					menu={{
						items: [
							{
								key: "0",
								label: "Notifications",
							},
							{
								type: "divider",
							},
							{
								key: "1",
								label: <RenderMenuNotification />,
							},
						],
					}}
					placement="bottomRight"
					overlayClassName="menu-submenu-notification-popup"
					trigger={["click"]}
				>
					<Badge count={notificationCount}>
						<FontAwesomeIcon
							className="menu-submenu-notification"
							icon={faBell}
						/>
					</Badge>
				</Dropdown>
			</div>
		</Layout.Header>
	);
}
