import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { Breadcrumb, Layout, Card } from "antd";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import Pusher from "pusher-js";

import { bgColor, name, userData } from "../../providers/companyInfo";
import Sidemenu from "./Sidemenu";
import Header from "./Header";
import Footer from "./Footer";

export default function Private(props) {
	const {
		children,
		moduleName,
		title,
		subtitle,
		breadcrumb,
		pageHeaderIcon,
		pageHeaderClass,
		pageId,
		pageClassName,
		dataPermissions,
	} = props;

	const queryClient = useQueryClient();
	const location = useLocation();
	const navigate = useNavigate();

	const [width, setWidth] = useState(window.innerWidth);

	const [sideMenuCollapse, setSideMenuCollapse] = useState(
		window.innerWidth <= 768 ? true : false
	);

	useEffect(() => {
		const section = document.querySelector(".private-layout");
		section.scrollIntoView({ behavior: "smooth", block: "start" });

		document.title = (moduleName ?? title).toUpperCase() + " | " + name;

		function handleResize() {
			setWidth(window.innerWidth);

			if (window.innerWidth === 768) {
				setSideMenuCollapse(true);
			}
			if (window.innerWidth > 768) {
				setSideMenuCollapse(false);
			}
		}
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, [title, moduleName]);

	useEffect(() => {
		// Enable pusher logging - don't include this in production
		Pusher.logToConsole = true;

		let pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
			cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
		});

		let channel = pusher.subscribe(process.env.REACT_APP_PUSHER_APP_CHANNEL);
		channel.bind(
			process.env.REACT_APP_PUSHER_APP_CHANNEL,
			function (receive_message) {
				let message = receive_message.message;
				if (
					message.type === "notification" &&
					message.user_id === userData().id &&
					window.location.origin === message.link_origin
				) {
					queryClient.refetchQueries("user_notifications");
				} else if (
					message.type === "message" &&
					message.user_id === userData().id &&
					window.location.origin === message.link_origin
				) {
					queryClient.refetchQueries("user_messages");
				}
			}
		);

		return () => {
			channel.unbind(process.env.REACT_APP_PUSHER_APP_CHANNEL);
			pusher.unsubscribe(process.env.REACT_APP_PUSHER_APP_CHANNEL);
		};
	}, [queryClient]);

	return (
		<>
			<div className="globalLoading hide">
				<SpinnerDotted thickness="100" color={bgColor} enabled={true} />
			</div>

			<Layout
				hasSider
				className={`private-layout ${pageClassName ?? ""}`}
				id={pageId ?? ""}
			>
				<Sidemenu
					location={location}
					sideMenuCollapse={sideMenuCollapse}
					setSideMenuCollapse={setSideMenuCollapse}
					width={width}
					dataPermissions={dataPermissions}
				/>

				<Layout className={sideMenuCollapse ? "ant-layout-has-collapse" : ""}>
					<Header
						sideMenuCollapse={sideMenuCollapse}
						setSideMenuCollapse={setSideMenuCollapse}
						width={width}
						pageHeaderClass={pageHeaderClass}
						pageHeaderIcon={pageHeaderIcon}
						title={title}
						subtitle={subtitle}
					/>

					<Layout.Content
						onClick={() => {
							if (width <= 767) {
								setSideMenuCollapse(true);
							}
						}}
					>
						<Breadcrumb
							separator={<span className="arrow" />}
							items={[
								{
									title: (
										<Link to="/">
											<FontAwesomeIcon icon={faHome} />
										</Link>
									),
								},
								...(breadcrumb && breadcrumb.length > 0
									? breadcrumb.map((item, index) => {
											let colorRed = "";
											if (breadcrumb.length > 1) {
												if (breadcrumb.length === index + 1) {
													colorRed = "breadcrumb-item-last";
												}
											}
											return {
												title: item.name,
												className: `cursor-pointer font-14px breadcrumb-item-text ${colorRed} ${
													item.className ? ` ${item.className}` : ""
												}`,
												id: item.id ?? "",
												onClick: () => {
													if (item.link) {
														navigate(item.link);
													}
												},
											};
									  })
									: []),
							]}
						/>

						<Card>{children}</Card>
					</Layout.Content>

					<Footer />
				</Layout>
			</Layout>
		</>
	);
}
