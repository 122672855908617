import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Button, Col, notification, Tabs } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faTable } from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";

import { GET, POST } from "../../../providers/useAxiosQuery";
import { useTableScrollOnTop } from "../../../providers/CustomTableFilter";
import notificationErrors from "../../../providers/notificationErrors";
import ModalFormFacultyLoad from "./components/ModalFormFacultyLoad";
import ModalFormFacultyLoadUploadExcel from "./components/ModalFormFacultyLoadUploadExcel";
import TableFacultyLoadList from "./components/TableFacultyLoadList";
import CalendarFacultyLoadList from "./components/CalendarFacultyLoadList";

export default function PageFacultyLoad(props) {
	const { refetchPermissions } = props;

	const location = useLocation();

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [dataCalendarView, setDataCalendarView] = useState([]);
	const [toggleModalUploadExcel, setToggleModalUploadExcel] = useState(false);

	const [toggleModalFormFacultyLoad, setToggleModalFormFacultyLoad] = useState({
		open: false,
		data: null,
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		isTrash: 0,
		calendar_view: 0,
		from: "page_faculty_monitoring_list",
	});

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/faculty_load?${new URLSearchParams(tableFilter)}`,
		"faculty_load_list",
		(res) => {
			console.log("res: ", res);
			if (res.data) {
				if (Number(res.calendar_view) === 1) {
					let dayToInt = {
						Sunday: 0,
						Monday: 1,
						Tuesday: 2,
						Wednesday: 3,
						Thursday: 4,
						Friday: 5,
						Saturday: 6,
					};

					let data = res.data.map((item) => {
						let day_schedule = dayToInt[item.day_schedule];
						let dateClass = day_schedule ? dayjs().day(day_schedule) : null;

						return {
							...item,
							title: `${item.fullname}`,
							start: dateClass ? dateClass.format("YYYY-MM-DD") : null,
							end: dateClass ? dateClass.format("YYYY-MM-DD") : null,
						};
					});
					setDataCalendarView(data);
				}
			}

			refetchPermissions();
		}
	);

	useEffect(() => {
		refetchSource();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	const handleTabChange = (key) => {
		setTableFilter((ps) => ({
			...ps,
			calendar_view: key === "2" ? 1 : 0,
		}));
	};

	const {
		mutate: mutateDeleteFacultyLoad,
		isLoading: isLoadingDeleteFacultyLoad,
	} = POST(`api/faculty_load_multiple_archived`, "faculty_load_list");

	const handleSelectedArchived = (isTrash) => {
		let data = {
			isTrash,
			ids: selectedRowKeys,
		};
		mutateDeleteFacultyLoad(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Faculty Monitoring",
						description: res.message,
					});
					setSelectedRowKeys([]);
				} else {
					notification.error({
						message: "Faculty Monitoring",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useTableScrollOnTop("tblFacultyLoadList", location.pathname);

	return (
		<Row gutter={[12, 12]} id="tbl_wrapper">
			<Col xs={24} sm={24} md={24}>
				<Button
					className="btn-main-primary min-w-150"
					onClick={() => setToggleModalUploadExcel(true)}
					name="btn_upload_excel"
				>
					Upload File Excel
				</Button>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Tabs
					defaultActiveKey="1"
					onChange={handleTabChange}
					indicator={{
						size: (origin) => origin - 25,
						align: "end",
					}}
					items={[
						{
							key: "1",
							icon: <FontAwesomeIcon icon={faTable} />,
							label: "Faculty Load",
							children: (
								<TableFacultyLoadList
									selectedRowKeys={selectedRowKeys}
									setSelectedRowKeys={setSelectedRowKeys}
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
									dataSource={dataSource}
									onChangeTable={onChangeTable}
									setToggleModalFormFacultyLoad={setToggleModalFormFacultyLoad}
									isLoadingDeleteFacultyLoad={isLoadingDeleteFacultyLoad}
									handleSelectedArchived={handleSelectedArchived}
								/>
							),
						},
						{
							key: "2",
							icon: <FontAwesomeIcon icon={faCalendarDays} />,
							label: "Calendar View",
							className: "calendar-view",
							children: (
								<CalendarFacultyLoadList
									selectedRowKeys={selectedRowKeys}
									setSelectedRowKeys={setSelectedRowKeys}
									tableFilter={tableFilter}
									setTableFilter={setDataCalendarView}
									dataSource={dataCalendarView}
									onChangeTable={onChangeTable}
									setToggleModalFormFacultyLoad={setToggleModalFormFacultyLoad}
									isLoadingDeleteFacultyLoad={isLoadingDeleteFacultyLoad}
									handleSelectedArchived={handleSelectedArchived}
								/>
							),
						},
					]}
				/>
			</Col>

			<ModalFormFacultyLoadUploadExcel
				toggleModalUploadExcel={toggleModalUploadExcel}
				setToggleModalUploadExcel={setToggleModalUploadExcel}
			/>
			<ModalFormFacultyLoad
				toggleModalFormFacultyLoad={toggleModalFormFacultyLoad}
				setToggleModalFormFacultyLoad={setToggleModalFormFacultyLoad}
			/>
		</Row>
	);
}
