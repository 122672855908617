import { useEffect, useState } from "react";
import { Row, Button, Col, Table, notification, Popconfirm } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";

import { DELETE, GET } from "../../../../providers/useAxiosQuery";
import {
	TableGlobalSearch,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
} from "../../../../providers/CustomTableFilter";
import ModalForm from "./ModalForm";
import notificationErrors from "../../../../providers/notificationErrors";

export default function PageDepartment() {
	const [toggleModalForm, setToggleModalForm] = useState({
		open: false,
		data: null,
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
	});

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/ref_department?${new URLSearchParams(tableFilter)}`,
		"department_list"
	);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	useEffect(() => {
		if (dataSource) {
			refetchSource();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const {
		mutate: mutateDeleteDepartment,
		isLoading: isLoadingDeleteDepartment,
	} = DELETE(`api/ref_department`, "department_list");

	const handleDelete = (record) => {
		mutateDeleteDepartment(record, {
			onSuccess: (res) => {
				console.log("res", res);
				if (res.success) {
					notification.success({
						message: "Department",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Department",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Button
						className=" btn-main-primary btn-main-invert-outline b-r-none"
						icon={<FontAwesomeIcon icon={faPlus} />}
						onClick={() =>
							setToggleModalForm({
								open: true,
								data: null,
							})
						}
					>
						Add Department
					</Button>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Row gutter={[12, 12]}>
						<Col xs={24} sm={24} md={24}>
							<div className="tbl-top-filter">
								<TablePageSize
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
								/>
								<TableGlobalSearch
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
								/>
							</div>
						</Col>
						<Col xs={24} sm={24} md={24}>
							<Table
								className="ant-table-default ant-table-striped"
								dataSource={dataSource && dataSource.data.data}
								rowKey={(record) => record.id}
								pagination={false}
								bordered={false}
								onChange={onChangeTable}
								scroll={{ x: "max-content" }}
							>
								<Table.Column
									title="Action"
									key="action"
									dataIndex="action"
									align="center"
									width={150}
									render={(text, record) => {
										return (
											<>
												<Button
													type="link"
													className="color-1"
													onClick={() =>
														setToggleModalForm({
															open: true,
															data: record,
														})
													}
												>
													<FontAwesomeIcon icon={faPencil} />
												</Button>
												<Popconfirm
													title="Are you sure to delete this data?"
													onConfirm={() => {
														handleDelete(record);
													}}
													onCancel={() => {
														notification.error({
															message: "Department",
															description: "Data not deleted",
														});
													}}
													okText="Yes"
													cancelText="No"
												>
													<Button
														type="link"
														className="danger-color"
														loading={isLoadingDeleteDepartment}
													>
														<FontAwesomeIcon icon={faTrash} />
													</Button>
												</Popconfirm>
											</>
										);
									}}
								/>
								<Table.Column
									title="Department"
									key="department_name"
									dataIndex="department_name"
									sorter={true}
								/>
							</Table>
						</Col>
						<Col xs={24} sm={24} md={24}>
							<div className="tbl-bottom-filter">
								<TableShowingEntriesV2 />
								<TablePagination
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
									total={dataSource?.data.total}
									showLessItems={true}
									showSizeChanger={false}
									tblIdWrapper="tbl_wrapper"
								/>
							</div>
						</Col>{" "}
					</Row>
				</Col>
				<ModalForm
					toggleModalForm={toggleModalForm}
					setToggleModalForm={setToggleModalForm}
				/>
			</Row>
		</>
	);
}
