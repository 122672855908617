import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";

import "../assets/scss/app.scss";
import RouteList from "./RouteList";

const queryClient = new QueryClient();

export default function Routers() {
	useEffect(() => {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.ready.then((registration) => {
				registration.update();
			});
		} else {
			console.warn("serviceWorker is unavailable");
		}

		const clearCacheData = () => {
			if ("caches" in window) {
				caches.keys().then((names) => {
					names.forEach((name) => {
						caches.delete(name);
					});
				});
			}
			console.log("Cache cleared");
		};

		const checkForUpdates = async () => {
			const storedVersion = localStorage.getItem("appVersion");

			let originAxios = await axios.get(window.location.origin + "/meta.json");

			if (originAxios && originAxios.data && originAxios.data.version) {
				let currentVersion = originAxios.data.version;

				if (storedVersion !== currentVersion) {
					clearCacheData();
					localStorage.setItem("appVersion", currentVersion);
				}
			}
		};

		checkForUpdates();

		return () => {};
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<RouteList />
			</Router>
		</QueryClientProvider>
	);
}
