import { useState } from "react";
import { Button, Empty, Modal } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import moment from "moment";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function ModalUploadFileStatusPreview(props) {
	const { toggleModalFormUploadFileShow, setToggleModalFormUploadFileShow } =
		props;

	const [numPages, setNumPages] = useState();
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = (e) => {
		setNumPages(e.numPages);
	};

	return (
		<Modal
			title="Grade File Preview"
			wrapClassName="modal-wrap-upload-file-preview"
			open={toggleModalFormUploadFileShow.open}
			onCancel={() => {
				setToggleModalFormUploadFileShow({
					open: false,
					data: null,
				});
			}}
			footer={null}
		>
			{toggleModalFormUploadFileShow.data &&
			toggleModalFormUploadFileShow.data.pdf_file ? (
				<>
					<Document
						file={toggleModalFormUploadFileShow.data.pdf_file}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page pageNumber={pageNumber} renderTextLayer={false}>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: 5,
									position: "absolute",
									right: 40,
									bottom: 100,
								}}
							>
								<img
									src={toggleModalFormUploadFileShow.data.signature}
									alt="pdf"
									style={{
										width: 120,
									}}
								/>
								<div
									style={{
										fontSize: 10,
										width: 60,
									}}
								>
									Date Created <br />
									{moment().format("MM/DD/YYYY HH:mm:ss")}
								</div>
							</div>
						</Page>
					</Document>

					<div className="action">
						<Button
							onClick={() => setPageNumber((ps) => (ps > 1 ? ps - 1 : 1))}
							className={pageNumber > 1 ? "btn-main-primary" : ""}
						>
							PREV
						</Button>
						<Button
							onClick={() =>
								setPageNumber((ps) => (ps < numPages ? ps + 1 : numPages))
							}
							className={numPages !== pageNumber ? "btn-main-primary" : ""}
						>
							NEXT
						</Button>
					</div>
				</>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No File Pdf" />
			)}
		</Modal>
	);
}
