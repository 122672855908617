import { Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHome,
	faCogs,
	faSheetPlastic,
	faLayerPlus,
	faEnvelopes,
} from "@fortawesome/pro-solid-svg-icons";
// import { faMoneyBill } from "@fortawesome/pro-regular-svg-icons";

export const adminHeaderMenuLeft = (
	<>
		{/* <div className="ant-menu-left-icon">
            <Link to="/subscribers/current">
                <span className="anticon">
                    <FontAwesomeIcon icon={faUsers} />
                </span>
                <Typography.Text>Subscribers</Typography.Text>
            </Link>
        </div> */}
	</>
);

export const adminHeaderDropDownMenuLeft = () => {
	const items = [
		// {
		//     key: "/subscribers/current",
		//     icon: <FontAwesomeIcon icon={faUsers} />,
		//     label: <Link to="/subscribers/current">Subscribers</Link>,
		// },
	];

	return <Menu items={items} />;
};

export const adminSideMenu = [
	{
		title: "Dashboard",
		path: "/dashboard",
		icon: <FontAwesomeIcon icon={faHome} />,
		moduleCode: "M-01",
	},
	{
		title: "Faculty Monitoring",
		path: "/faculty-load",
		icon: <FontAwesomeIcon icon={faSheetPlastic} />,
		children: [
			{
				title: "List",
				path: "/faculty-load/list",
				moduleCode: "M-02",
			},
			{
				title: "Justification",
				path: "/faculty-load/justification",
				moduleCode: "M-03",
			},
			{
				title: "Deduction",
				path: "/faculty-load/deduction",
				moduleCode: "M-04",
			},
			{
				title: "Absent List",
				path: "/faculty-load/absent-list",
				moduleCode: "M-05",
			},
			{
				title: "Present List",
				path: "/faculty-load/present-list",
				moduleCode: "M-06",
			},
			{
				title: "Report",
				path: "/faculty-load/reports",
				moduleCode: "M-07",
			},
		],
	},
	{
		title: "Grade Submission",
		path: "/grade-tracker",
		icon: <FontAwesomeIcon icon={faLayerPlus} />,
		children: [
			{
				title: "List",
				path: "/grade-tracker/list",
				moduleCode: "M-08",
			},
			{
				title: "For Approval",
				path: "/grade-tracker/approval",
				moduleCode: "M-09",
			},
			{
				title: "Approved List",
				path: "/grade-tracker/approved",
				moduleCode: "M-10",
			},
			{
				title: "Reports",
				path: "/grade-tracker/reports",
				moduleCode: "M-11",
			},
		],
	},
	{
		title: "Settings",
		path: "/settings",
		icon: <FontAwesomeIcon icon={faCogs} />,
		moduleCode: "M-12",
	},
	{
		title: "Email Template",
		path: "/email-template",
		icon: <FontAwesomeIcon icon={faEnvelopes} />,
		moduleCode: "M-13",
	},
];
