import { Row, Col, Collapse } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import Highcharts from "highcharts";

import { GET } from "../../../providers/useAxiosQuery";
import { userData } from "../../../providers/companyInfo";
import FacultyMonitoringGraph from "./components/CollapseItemFacultyMonitoringGraph";
import highchartsSetOptions from "../../../providers/highchartsSetOptions";
import FacultyGradeSubmissionGraph from "./components/FacultyGradeSubmissionGraph";
import FacultyMonitoringListGraph from "./components/FacultyMonitoringListGraph";
import FacultyMonitoringDepartmentListGraph from "./components/FacultyMonitoringDepartmentListGraph";
import FacultyMonitoringCalendar from "./components/FacultyMonitoringCalendar";
import FacultyMonitoringDepartmentPieGraph from "./components/FacultyMonitoringDepartmentPieGraph";
import FacultyMonitoringFacultyTopGraph from "./components/FacultyMonitoringFacultyTopGraph";

require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

export default function PageDashboard() {
	highchartsSetOptions(Highcharts);

	// const { data: dataDepartments } = GET(
	// 	`api/ref_department`,
	// 	"department_select"
	// );

	const { data: dataSourceFacultyLoadMonitoringList } = GET(
		`api/faculty_load_monitoring_list_graph`,
		"faculty_load_monitoring_list_graph",
		(res) => {
			console.log("faculty_load_monitoring_list_graph", res);
		},
		false
	);

	return (
		<Row gutter={[20, 20]}>
			<Col
				xs={24}
				sm={24}
				md={24}
				lg={24}
				xl={24}
				xxl={24}
				className="card-list"
			>
				<FacultyMonitoringListGraph
					Highcharts={Highcharts}
					dataSource={dataSourceFacultyLoadMonitoringList}
				/>
			</Col>

			{userData().user_role_id !== 5 && (
				<Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6} className="card-list">
					<FacultyMonitoringDepartmentListGraph
						Highcharts={Highcharts}
						dataSource={dataSourceFacultyLoadMonitoringList}
					/>
				</Col>
			)}

			<Col
				xs={24}
				sm={24}
				md={userData().user_role_id !== 5 ? 10 : 18}
				lg={userData().user_role_id !== 5 ? 12 : 18}
				xl={userData().user_role_id !== 5 ? 12 : 18}
				xxl={userData().user_role_id !== 5 ? 12 : 18}
			>
				<Collapse
					defaultActiveKey={["1", "2", "3"]}
					expandIconPosition="end"
					expandIcon={({ isActive }) =>
						isActive ? (
							<FontAwesomeIcon icon={faAngleUp} />
						) : (
							<FontAwesomeIcon icon={faAngleDown} />
						)
					}
					items={
						userData().user_role_id !== 3 &&
						userData().user_role_id !== 4 &&
						userData().user_role_id !== 5
							? [
									{
										key: "1",
										className: "collapse-main-primary",
										label: `${
											userData().user_role_id !== 3 &&
											userData().user_role_id !== 4 &&
											userData().user_role_id !== 5
												? "HR"
												: ""
										} MONITORING`,
										children: (
											<FacultyMonitoringGraph Highcharts={Highcharts} />
										),
									},
									{
										key: "2",
										className: "collapse-main-primary",
										label: `FACULTY TOP`,
										children: (
											<FacultyMonitoringFacultyTopGraph
												Highcharts={Highcharts}
											/>
										),
									},
									{
										key: "3",
										className: "collapse-main-primary",
										label: `GRADE SUBMISSION`,
										children: (
											<FacultyGradeSubmissionGraph Highcharts={Highcharts} />
										),
									},
							  ]
							: [
									{
										key: "1",
										className: "collapse-main-primary",
										label: `${
											userData().user_role_id !== 3 &&
											userData().user_role_id !== 4 &&
											userData().user_role_id !== 5
												? "HR"
												: ""
										} MONITORING`,
										children: (
											<FacultyMonitoringGraph Highcharts={Highcharts} />
										),
									},
							  ]
					}
				/>
			</Col>
			<Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6} className="card-list">
				<Row gutter={[20, 20]}>
					{dataSourceFacultyLoadMonitoringList &&
					dataSourceFacultyLoadMonitoringList.departmentList &&
					dataSourceFacultyLoadMonitoringList.departmentList.length > 0 ? (
						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
							<FacultyMonitoringDepartmentPieGraph
								Highcharts={Highcharts}
								departmentList={
									dataSourceFacultyLoadMonitoringList &&
									dataSourceFacultyLoadMonitoringList.departmentList
										? dataSourceFacultyLoadMonitoringList.departmentList
										: []
								}
							/>
						</Col>
					) : null}

					<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
						<FacultyMonitoringCalendar />
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
