import { Card } from "antd";
import { useEffect } from "react";

export default function FacultyMonitoringDepartmentPieGraph(props) {
	const { Highcharts, departmentList } = props;

	useEffect(() => {
		const divFacultyMonitoringDepartmentPieGraph = document.getElementById(
			"divFacultyMonitoringDepartmentPieGraph"
		);

		if (divFacultyMonitoringDepartmentPieGraph) {
			Highcharts.chart("divFacultyMonitoringDepartmentPieGraph", {
				chart: {
					type: "pie",
				},
				title: {
					text: "Department Absentees",
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: "pointer",
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
					},
				},
				series: [
					{
						name: "Department Absentees",
						colorByPoint: true,
						data: departmentList.map((item) => ({
							name: item.department_name,
							y: item.count,
						})),
					},
				],
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [departmentList]);

	return (
		<Card>
			<div id="divFacultyMonitoringDepartmentPieGraph" />
		</Card>
	);
}
