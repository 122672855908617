import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Button, Col, Table, notification, DatePicker, Flex } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFileSpreadsheet,
	faFolderImage,
	faMarker,
} from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";

import { GET } from "../../../providers/useAxiosQuery";
import { apiUrl, userData } from "../../../providers/companyInfo";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../providers/CustomTableFilter";
import ModalFormFacultyLoadJustification from "./components/ModalFormFacultyLoadJustification";
import ModalFormFacultyLoadJustificationAttachment from "./components/ModalFormFacultyLoadJustificationAttachment";

export default function PageFacultyLoadJustification(props) {
	const { refetchPermissions } = props;

	const location = useLocation();

	// const [form] = Form.useForm();

	const [toggleModalFormJustification, setToggleModalFormJustification] =
		useState({
			open: false,
			data: null,
		});
	const [
		toggleModalFormJustificationAttachment,
		setToggleModalFormJustificationAttachment,
	] = useState({
		open: false,
		data: [],
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		isTrash: 0,
		from: location.pathname,
		building_id: [],
		floor_id: [],
		room_id: [],
		status_id: "",
		department_id: "",
		date_range: [],
		date_range_format: [],
	});

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/flm_justification?${new URLSearchParams(tableFilter)}`,
		"flm_justification_list",
		(res) => {
			refetchPermissions();
		}
	);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	useEffect(() => {
		if (dataSource) {
			refetchSource();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	useTableScrollOnTop("tblFacultyLoadJustificationList", location.pathname);

	return (
		<Row gutter={[12, 12]} id="tbl_wrapper">
			<Col xs={24} sm={24} md={24}>
				<div className="filter-date-range">
					<DatePicker.RangePicker
						size="large"
						className="w-100"
						format={["MM/DD/YYYY", "MM/DD/YYYY"]}
						value={tableFilter.date_range}
						onChange={(date, dateString) => {
							setTableFilter((prevState) => ({
								...prevState,
								date_range: date ?? [],
								date_range_format: dateString ?? [],
							}));
						}}
					/>
				</div>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<div className="tbl-top-filter">
					<Flex gap={15}>
						<Button
							className="btn-main-primary"
							icon={
								<FontAwesomeIcon icon={faFileSpreadsheet} className="mr-5" />
							}
							onClick={() => {
								if (tableFilter.date_range_format.length > 0) {
									let data = {
										date_start: dayjs(tableFilter.date_range_format[0]).format(
											"YYYY-MM-DD"
										),
										date_end: dayjs(tableFilter.date_range_format[1]).format(
											"YYYY-MM-DD"
										),
										from: "Faculty Load Justification",
									};
									window.open(
										apiUrl(
											`api/faculty_load_report_print?${new URLSearchParams(
												data
											)}`
										)
									);
								} else {
									notification.error({
										message: "Date Range",
										description: "Please select date range",
									});
								}
							}}
						>
							Excel Print
						</Button>

						<Button
							className="btn-main-primary"
							icon={
								<FontAwesomeIcon icon={faFileSpreadsheet} className="mr-5" />
							}
							onClick={() => {
								/** let data = {
								date_start: dayjs(tableFilter.date_range_format[0]).format(
									"YYYY-MM-DD"
								),
								date_end: dayjs(tableFilter.date_range_format[1]).format(
									"YYYY-MM-DD"
								),
								from: "Faculty Load Justification",
							};
							window.open(
								apiUrl(
									`api/faculty_load_report_print?${new URLSearchParams(data)}`
								)
							);*/
							}}
						>
							PDF Print
						</Button>
					</Flex>

					<TablePageSize
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>
				</div>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<div className="tbl-top-filter">
					<TableGlobalSearchAnimated
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>

					<Flex gap={15}>
						<TableShowingEntriesV2 />

						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</div>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Table
					id="tblFacultyLoadJustificationList"
					className="ant-table-default ant-table-striped"
					dataSource={dataSource && dataSource.data.data}
					rowKey={(record) => record.id}
					pagination={false}
					bordered={false}
					onChange={onChangeTable}
					scroll={{ x: "max-content" }}
					sticky
				>
					<Table.Column
						title="Action"
						key="action"
						align="center"
						width={120}
						render={(_, record) => {
							return (
								<Flex gap={15} justify="center">
									{[1, 2, 3, 4].includes(userData().user_role_id) ? (
										<Button
											icon={<FontAwesomeIcon icon={faMarker} />}
											type="link"
											className="btn-main-primary"
											name="btn_edit"
											onClick={() =>
												setToggleModalFormJustification({
													open: true,
													data: record,
												})
											}
										/>
									) : null}

									<Button
										icon={<FontAwesomeIcon icon={faFolderImage} />}
										type="link"
										className="btn-info"
										onClick={() =>
											setToggleModalFormJustificationAttachment({
												open: true,
												data: record.attachments,
											})
										}
									/>
								</Flex>
							);
						}}
					/>

					<Table.Column
						title="Name"
						key="fullname"
						dataIndex="fullname"
						sorter
						width={200}
					/>

					<Table.Column
						title="Status"
						key="status"
						dataIndex="status"
						sorter
						width={180}
					/>
					<Table.Column
						title="Remarks"
						key="remaks_new"
						dataIndex="remaks_new"
						sorter
						width={250}
					/>

					<Table.Column
						title="Approved By"
						key="approved_by_name"
						dataIndex="approved_by_name"
						sorter
						width={200}
					/>

					<Table.Column
						title="Date Approved"
						key="date_approved"
						dataIndex="date_approved"
						sorter
						width={200}
					/>

					<Table.Column
						title="Date Scheduled"
						key="date_reported"
						dataIndex="date_reported"
						sorter
						width={200}
					/>

					<Table.Column
						title="Time Scheduled"
						key="time"
						dataIndex="time"
						sorter
						width={200}
					/>
				</Table>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<div className="tbl-bottom-filter">
					<TableShowingEntriesV2 />
					<TablePagination
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
						total={dataSource?.data.total}
						showLessItems={true}
						showSizeChanger={false}
						tblIdWrapper="tbl_wrapper"
					/>
				</div>
			</Col>

			<ModalFormFacultyLoadJustification
				toggleModalFormJustification={toggleModalFormJustification}
				setToggleModalFormJustification={setToggleModalFormJustification}
			/>

			<ModalFormFacultyLoadJustificationAttachment
				toggleModalFormJustificationAttachment={
					toggleModalFormJustificationAttachment
				}
				setToggleModalFormJustificationAttachment={
					setToggleModalFormJustificationAttachment
				}
			/>
		</Row>
	);
}
