import { useState } from "react";
import { Modal, Button, notification, Upload, Progress } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/pro-regular-svg-icons";

import { POST } from "../../../../providers/useAxiosQuery";
import notificationErrors from "../../../../providers/notificationErrors";

export default function ModalFormFacultyLoadUploadExcel(props) {
	const { toggleModalUploadExcel, setToggleModalUploadExcel } = props;

	const [fileExcel, setFileExcel] = useState(null);
	const [onProgress, setOnProgress] = useState(0);
	const [onProgressStatus, setOnProgressStatus] = useState("active");
	const [onProgressMessage, setOnProgressMessage] = useState(null);

	const { mutate: mutateFormUpload, isLoading: isLoadingFormUpload } = POST(
		"api/faculty_load_upload",
		"faculty_load_list",
		true,
		(res) => {},
		setOnProgress
	);

	const handleCustomRequest = () => {
		if (fileExcel) {
			let formData = new FormData();
			formData.append("file_excel", fileExcel, fileExcel.name);

			mutateFormUpload(formData, {
				onSuccess: (res) => {
					// console.log("mutateFormUpload res", res);
					if (res.success) {
						notification.success({
							message: "Faculty Load",
							description: res.message,
						});
						setToggleModalUploadExcel(false);
						setOnProgressStatus("success");
						setOnProgress(0);
						setFileExcel(null);
					} else {
						notification.error({
							message: "Faculty Load",
							description: res.message,
						});
						setOnProgressStatus("exception");
						setOnProgressMessage(res.message);
					}
				},
				onError: (err) => {
					notificationErrors(err);
					setOnProgressStatus("exception");
				},
			});
		}
	};

	const propsUpload = {
		name: "file",
		listType: "picture",
		maxCount: 1,
		multiple: false,
		showUploadList: false,
		fileList: [],
		beforeUpload: (file) => {
			const isExcel =
				file.type ===
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
				file.type === "application/vnd.ms-excel";

			if (!isExcel) {
				notification.error({
					message: "Faculty Load",
					description: `Format is not acceptable`,
				});
			} else {
				setFileExcel(file);
			}

			return isExcel ? true : Upload.LIST_IGNORE;
		},

		// onChange(info) {
		// 	const { status } = info.file;
		// 	if (status !== "uploading") {
		// 		console.log(info.file, info.fileList);
		// 	}
		// 	if (status === "done") {
		// 		// message.success(`${info.file.name} file uploaded successfully.`);
		// 		notification.error({
		// 			message: "Faculty Load",
		// 			description: "Something Went Wrong",
		// 		});
		// 	} else if (status === "error") {
		// 		message.error(`${info.file.name} file upload failed.`);
		// 	}
		// },
	};

	return (
		<Modal
			title="UPLOAD FILE EXCEL"
			open={toggleModalUploadExcel}
			wrapClassName="modal-wrap-faculty-load-upload-excel"
			onCancel={() => {
				setToggleModalUploadExcel(false);
				setOnProgressStatus("active");
				setOnProgress(0);
				setFileExcel(null);
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={0}
					onClick={() => {
						setToggleModalUploadExcel(false);
						setOnProgressStatus("active");
						setOnProgress(0);
						setFileExcel(null);
					}}
					disabled={isLoadingFormUpload}
				>
					Cancel
				</Button>,
				<Button
					className="btn-main-primary outlined"
					key={2}
					onClick={() => {
						setOnProgressStatus("active");
						setOnProgress(0);
						setFileExcel(null);
					}}
					disabled={isLoadingFormUpload}
				>
					Clear
				</Button>,
				<Button
					className="btn-main-primary"
					key={1}
					onClick={() => {
						handleCustomRequest();
					}}
					disabled={isLoadingFormUpload}
				>
					Submit
				</Button>,
			]}
		>
			<a
				href={
					window.location.origin +
					"/assets/excel-format/Faculty Load Summary FORMAT.xls"
				}
			>
				Download Faculty Load Summary FORMAT.xls
			</a>

			<Button
				className="upload-faculty-load"
				disabled={isLoadingFormUpload}
				style={{
					position: "relative",
				}}
			>
				{(isLoadingFormUpload || onProgressStatus === "exception") && (
					<div className="upload-faculty-load-progress">
						<Progress
							type="circle"
							percent={onProgress}
							status={onProgressStatus}
						/>

						<div
							className={`loading-wrapper ${
								!onProgressMessage ? "loading" : ""
							}`}
						>
							{onProgressMessage ? onProgressMessage : "Uploading, please wait"}
						</div>
					</div>
				)}

				<Upload {...propsUpload}>
					<p className="ant-upload-drag-icon ">
						<FontAwesomeIcon icon={faArrowUpFromBracket} />
					</p>
					<p className="ant-upload-text">
						Click or drag file to this area to upload
					</p>
					<p className="ant-upload-hint">
						Support for a single upload. Strictly prohibited from uploading
						banned files or other file type.
					</p>
				</Upload>
			</Button>

			{fileExcel && (
				<div className="upload-faculty-load-file">{fileExcel.name}</div>
			)}
		</Modal>
	);
}
