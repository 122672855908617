import { useCallback, useState } from "react";
import { debounce } from "lodash";
import { Row, Col, Button, Form, Collapse, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAngleDown,
	faAngleUp,
	faCamera,
} from "@fortawesome/pro-regular-svg-icons";

import { GET, POST } from "../../../providers/useAxiosQuery";
import {
	apiUrl,
	// apiUrl,
	defaultProfile,
	userData,
} from "../../../providers/companyInfo";
import FloatInput from "../../../providers/FloatInput";
import FloatSelect from "../../../providers/FloatSelect";
import FloatInputMask from "../../../providers/FloatInputMask";
import validateRules from "../../../providers/validateRules";
import ModalFormEmail from "./components/ModalFormEmail";
import ModalFormPassword from "./components/ModalFormPassword";
import notificationErrors from "../../../providers/notificationErrors";
import ModalUploadProfilePicture from "./components/ModalUploadProfilePicture";
import SignaturePad from "./components/SignaturePad";

export default function PageEditProfile() {
	const [form] = Form.useForm();

	const [userInfo, setUserInfo] = useState({
		username: "",
		email: "",
		department_id: "",
		school_id: "",
		firstname: "",
		lastname: "",
		contact_number: "",
		gender: "",
		civil_status_id: "",
		nationality_id: "",
	});

	const [toggleModalFormEmail, setToggleModalFormEmail] = useState({
		open: false,
		data: null,
	});

	const [toggleModalFormPassword, setToggleModalFormPassword] = useState({
		open: false,
		data: null,
	});

	const [toggleModalUploadProfilePicture, setToggleModalUploadProfilePicture] =
		useState({
			open: false,
			file: null,
			src: null,
			is_camera: null,
			fileName: null,
		});

	const [fileSignature, setFileSignature] = useState({
		file: null,
		src: null,
		filePath: null,
		fileName: null,
	});

	const { data: dataCivilStatuses } = GET(
		`api/ref_civilstatus`,
		"civil_status_select"
	);

	const { data: dataNationalities } = GET(
		`api/ref_nationality`,
		"ref_nationality_select"
	);

	GET(`api/users/${userData().id}`, "users_info", (res) => {
		if (res.data) {
			let data = res.data;

			let username = data.username;
			let email = data.email;
			let school_id = data.profile.school_id;
			let firstname = data.profile.firstname;
			let lastname = data.profile.lastname;

			let gender = "";

			if (
				data.profile &&
				data.profile.gender &&
				data.profile.gender.length > 0
			) {
				gender = data.profile.gender[0].gender;
			}

			let contact_number = "";

			if (
				data.profile &&
				data.profile.profile_contact_informations &&
				data.profile.profile_contact_informations.length
			) {
				contact_number =
					data.profile.profile_contact_informations[0].contact_number;
			}

			let department_id = "";

			if (
				data.profile &&
				data.profile.profile_departments &&
				data.profile.profile_departments.length
			) {
				department_id = data.profile.profile_departments[0].department_id;
			}

			let profilePicture = data.profile.attachments.filter(
				(f) => f.file_description === "Profile Picture"
			);

			if (profilePicture.length > 0) {
				setToggleModalUploadProfilePicture((ps) => ({
					...ps,
					src: apiUrl(profilePicture[0].file_path),
				}));
			}

			let signature = data.profile.attachments.filter(
				(f) => f.file_description === "Signature"
			);

			if (signature.length > 0) {
				setFileSignature({
					file: null,
					src: null,
					filePath: apiUrl(signature[0].file_path),
					fileName: null,
				});
			}

			setUserInfo({
				username,
				email,
				department_id,
				school_id,
				firstname,
				lastname,
				contact_number,
				gender,
				civil_status_id: data.profile.civil_status_id,
				nationality_id: data.profile.nationality_id,
			});
			form.setFieldsValue({
				username,
				email,
				department_id,
				school_id,
				firstname,
				lastname,
				contact_number,
				gender,
				civil_status_id: data.profile.civil_status_id,
				nationality_id: data.profile.nationality_id,
			});
		}
	});

	const { mutate: mutateUpdateInfo } = POST(
		`api/user_profile_info_update`,
		"user_profile_info_update"
	);

	const onFinish = (values) => {
		console.log("onFinish", values);

		let data = {
			...values,
			contact_number: values.contact_number
				? values.contact_number.split(" ").join("")
				: "",
		};

		mutateUpdateInfo(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "User",
						description: res.message,
					});
				} else {
					notification.success({
						message: "User",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const handleTriggerDebounce = debounce((field, value) => {
		console.log("field, value", field, value, userInfo[field]);
		let oldValue = userInfo[field];
		if (field === "contact_number") {
			if (!oldValue) {
				oldValue = "";
			}
			if (value) {
				value = value.split("_").join("");
				value = value.split(" ").join("");

				if (oldValue !== value) {
					form.submit();
				}
			} else {
				if (oldValue !== value) {
					form.submit();
				}
			}
		} else {
			if (oldValue !== value) {
				form.submit();
			}
		}
	}, 1000);

	const handleDebounce = useCallback(
		(field, value) => {
			handleTriggerDebounce(field, value);
		},
		[handleTriggerDebounce]
	);

	return (
		<Form form={form} onFinish={onFinish}>
			<Row gutter={[12, 12]}>
				<Col
					sm={24}
					md={24}
					lg={16}
					xl={16}
					xxl={16}
					className="collapse-wrapper-info"
				>
					<Collapse
						className="collapse-main-primary"
						defaultActiveKey={["0", "1"]}
						size="large"
						expandIcon={({ isActive }) => (
							<FontAwesomeIcon icon={isActive ? faAngleUp : faAngleDown} />
						)}
						items={[
							{
								key: "0",
								label: "ACCOUNT INFORMATION",
								children: (
									<Row gutter={[12, 0]}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item name="username">
												<FloatInput
													label="Username"
													placeholder="Username"
													disabled
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item name="email">
												<FloatInput
													label="Email"
													placeholder="Email"
													disabled
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Button
												type="link"
												className="p-0"
												onClick={() =>
													setToggleModalFormEmail({
														open: true,
														data: {
															id: userData().id,
														},
													})
												}
											>
												Change Email
											</Button>
										</Col>

										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Button
												type="link"
												className="p-0"
												onClick={() =>
													setToggleModalFormPassword({
														open: true,
														data: {
															id: userData().id,
														},
													})
												}
											>
												Change Password
											</Button>
										</Col>
									</Row>
								),
							},
							{
								key: "1",
								label: "PERSONAL INFORMATION",
								children: (
									<Row gutter={[12, 12]}>
										<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
											<Form.Item name="school_id">
												<FloatInput
													label="School ID"
													placeholder="School ID"
													disabled
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}></Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="firstname"
												rules={[validateRules.required()]}
											>
												<FloatInput
													label="First Name"
													placeholder="First Name"
													required
													onChange={(e) =>
														handleDebounce("firstname", e.target.value)
													}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={12} lg={12} xl={12}>
											<Form.Item
												name="lastname"
												rules={[validateRules.required()]}
											>
												<FloatInput
													label="Last Name"
													placeholder="Last Name"
													required
													onChange={(e) =>
														handleDebounce("lastname", e.target.value)
													}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
											<Form.Item
												name="contact_number"
												rules={[validateRules.phone]}
											>
												<FloatInputMask
													label="Phone No"
													placeholder="Phone No"
													maskLabel="contact_number"
													maskType="999 999 9999"
													onChange={(e) =>
														handleDebounce("contact_number", e.target.value)
													}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
											<Form.Item name="gender">
												<FloatSelect
													label="Gender"
													placeholder="Gender"
													options={[
														{
															label: "Male",
															value: "Male",
														},
														{
															label: "Female",
															value: "Female",
														},
													]}
													onChange={(e) => handleDebounce("gender", e)}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
											<Form.Item name="civil_status_id">
												<FloatSelect
													label="Civil Status"
													placeholder="Civil Status"
													options={
														dataCivilStatuses && dataCivilStatuses.data
															? dataCivilStatuses.data.map((item) => ({
																	value: item.id,
																	label: item.civil_status,
															  }))
															: []
													}
													onChange={(e) => handleDebounce("civil_status_id", e)}
												/>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
											<Form.Item name="nationality_id">
												<FloatSelect
													label="Citizenship"
													placeholder="Citizenship"
													options={
														dataNationalities && dataNationalities.data
															? dataNationalities.data.map((item) => ({
																	value: item.id,
																	label: item.nationality,
															  }))
															: []
													}
													onChange={(e) => handleDebounce("nationality_id", e)}
												/>
											</Form.Item>
										</Col>
									</Row>
								),
							},
						]}
					/>
				</Col>

				<Col
					xs={24}
					sm={24}
					md={24}
					lg={8}
					xl={8}
					xxl={8}
					className="collapse-wrapper-photo"
				>
					<Collapse
						className="collapse-main-primary"
						defaultActiveKey={["0", "1"]}
						size="large"
						expandIcon={({ isActive }) => (
							<FontAwesomeIcon icon={isActive ? faAngleUp : faAngleDown} />
						)}
						items={[
							{
								key: "0",
								label: "Profile Photo",
								children: (
									<Row gutter={[12, 0]}>
										<Col xs={24} sm={24} md={24} lg={24}>
											<div className="profile-picture-wrapper">
												<img
													alt=""
													src={
														toggleModalUploadProfilePicture.src
															? toggleModalUploadProfilePicture.src
															: defaultProfile
													}
												/>

												<Button
													type="link"
													icon={<FontAwesomeIcon icon={faCamera} />}
													className="btn-upload"
													onClick={() =>
														setToggleModalUploadProfilePicture((ps) => ({
															...ps,
															open: true,
														}))
													}
												/>
											</div>

											<ModalUploadProfilePicture
												toggleModalUploadProfilePicture={
													toggleModalUploadProfilePicture
												}
												setToggleModalUploadProfilePicture={
													setToggleModalUploadProfilePicture
												}
											/>
										</Col>
									</Row>
								),
							},
							{
								key: "1",
								label: "Signature",
								className: "collapse-signature",
								children: (
									<Row gutter={[12, 0]}>
										<Col xs={24} sm={24} md={24} lg={24}>
											<SignaturePad
												fileSignature={fileSignature}
												setFileSignature={setFileSignature}
											/>
										</Col>
									</Row>
								),
							},
						]}
					/>
				</Col>
			</Row>

			<ModalFormEmail
				toggleModalFormEmail={toggleModalFormEmail}
				setToggleModalFormEmail={setToggleModalFormEmail}
			/>

			<ModalFormPassword
				toggleModalFormPassword={toggleModalFormPassword}
				setToggleModalFormPassword={setToggleModalFormPassword}
			/>
		</Form>
	);
}
